.dl-page-container {
  display: flex;
  width: 100vw;
}

.dl-image-container {
  flex: 3.15;
  display: flex;
  overflow: hidden;
  width: 75vw;

  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.dl-image-container>.image.cover {
  min-height: 75vh;
  min-width: 75%;
  position: absolute;
  top: 0;
  right: 25vw;
  bottom: 35vh;
}

.dl-image-container>.image.banner {
  width: 75%;
  position: absolute;
  left: 0;
  bottom: 0vw;
  right: 25vw;
}

.dl-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  height: 100vh;
}

.dl-tether-point-container {
  height: 170px;
  width: 25vw;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 1024px) and (max-height: 1368px) {
    text-align: center;
    height: 100px;
    width: 100vw;
  }
}

.dl-tether-point {
  height: 100%;
}

.playstore-container {
  margin-top: 30px;
}


.dl-signin-header {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 27px;
  margin-bottom: 20px;
  margin-top: 20px;

  @media screen and (max-width: 1024px) {
    font-size: 24px;
  }
}

.send-invite-txt {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 17px;
}

.playstore-button {
  width: 225px;
  height: 70px;
}

.google-playstore {
  width: 100%;
}

.apple-playstore {
  width: 100%;
}